/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import type { ComponentType, ErrorInfo, ReactNode } from 'react';
import React, { Component, useCallback, useContext } from 'react';

import UFOInteractionContext from '@atlaskit/react-ufo/interaction-context';
import UFOInteractionIDContext from '@atlaskit/react-ufo/interaction-id-context';
import { addError } from '@atlaskit/react-ufo/interaction-metrics';

import { hashDataAsByte } from '@confluence/hash';
import { markErrorAsHandled, isErrorMarkedAsHandled } from '@confluence/graphql-error-processor';
import { getMonitoringClient, setReactErrorAttributes } from '@confluence/monitoring';
import { KnownErrorBoundaryContext } from '@confluence/known-error-boundary';

import type { AttributionContextValue } from './AttributionContext';
import { AttributionContext, useAttribution } from './AttributionContext';
import { IntlNextErrorBoundary } from './IntlNextErrorBoundary';

export type ErrorComponentProps = {
	attribution: AttributionContextValue;
	error: Error;
	errorInfo?: ErrorInfo;
	customTitle?: string;
	isFullSizeErrorComponent?: boolean;
};

export type GenericErrorBoundaryProps = {
	attribution: AttributionContextValue;
	children?: ReactNode;
	onError?(error: Error, info: ErrorInfo): void;
	renderOnError: ComponentType<ErrorComponentProps>;
};

/**
 * Implements as minial as possible a `Component` to allow
 * `GenericErrorBoundary` to be a function component.
 */
class GenericErrorBoundaryComponent extends Component<
	GenericErrorBoundaryProps & {
		onError(error: Error, info: ErrorInfo): void;
	},
	{ error?: Error; errorInfo?: ErrorInfo }
> {
	componentDidMount() {
		this.checkForAttributionError();
	}

	componentDidUpdate() {
		this.checkForAttributionError();
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.props.onError(error, errorInfo);
		this.setState({ error, errorInfo });
	}

	checkForAttributionError() {
		if (process.env.NODE_ENV !== 'production') {
			if (!this.props.attribution) {
				throw new TypeError('GenericErrorBoundary requires truthy attribution!');
			}
		}
	}

	render() {
		const { attribution, children, renderOnError: RenderOnError } = this.props;

		return this.state && this.state.error && this.state.errorInfo ? (
			<RenderOnError
				attribution={attribution}
				error={this.state.error}
				errorInfo={this.state.errorInfo}
			/>
		) : (
			children
		);
	}
}

export function GenericErrorBoundary(props: GenericErrorBoundaryProps) {
	const { children, onError, renderOnError: RenderOnError } = props;

	const attribution = useAttribution(props.attribution);

	const ufoInteractionId = useContext(UFOInteractionIDContext);
	const ufoContext = useContext(UFOInteractionContext);

	const isKnownError = useContext(KnownErrorBoundaryContext);
	const handleError = useCallback(
		(error: Error, errorInfo: ErrorInfo) => {
			if (ufoInteractionId?.current && ufoContext?.labelStack) {
				addError(
					ufoInteractionId.current,
					`GenericErrorBoundary:${attribution}`,
					ufoContext.labelStack,
					error.name,
					error.message,
					error.stack,
				);
			}

			if (isKnownError && isKnownError(error)) {
				throw error;
			}

			setReactErrorAttributes(error, {
				componentStack: errorInfo.componentStack ?? '',
				errorHashCode: computeErrorHashCode(error, errorInfo),
			});

			if (!isErrorMarkedAsHandled(error)) {
				// only report errors which haven't been marked as handled yet
				getMonitoringClient().submitError(error, {
					attribution,
				});
				markErrorAsHandled(error);
			}

			if (onError) {
				onError(error, errorInfo);
			}
		},
		[attribution, isKnownError, onError, ufoInteractionId, ufoContext],
	);

	const renderOnError = useCallback(
		(props: ErrorComponentProps) => <RenderOnError {...props} />,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<AttributionContext.Provider value={attribution}>
			<GenericErrorBoundaryComponent
				attribution={attribution}
				onError={handleError}
				renderOnError={renderOnError}
			>
				<IntlNextErrorBoundary>{children}</IntlNextErrorBoundary>
			</GenericErrorBoundaryComponent>
		</AttributionContext.Provider>
	);
}

GenericErrorBoundary.displayName = 'GenericErrorBoundary';
GenericErrorBoundary.propTypes = {
	attribution: PropTypes.string.isRequired,
	onError: PropTypes.func,
	renderOnError: PropTypes.func.isRequired,
};

/**
 * Used to group errors containing a specified string under a stable hash
 * by providing a static input that always produces the same hash.
 *
 * ex. "Some error text to look for": "stableHashInputToEncode"
 *
 * IMPORTANT: Please use this functionality sparingly. If you do happen to add a new stable
 * hash input to this list, please document the generated hash code (ex. "g7s4p"), notify
 * the Counfluence Cloud support team of the new hash code, and consider adding it to
 * this document: https://hello.atlassian.net/wiki/spaces/~7012120740227026c440094e9900a280bba1c/pages/2918914324/Confluence+error+codes
 *
 * More context: https://product-fabric.atlassian.net/browse/PCC-3398
 */
const stableHashMap: Record<string, string> = {
	'Minified React error #301': 'React error #301',
};

export function computeErrorHashCode(error: Error, errorInfo: ErrorInfo) {
	let bytes: number[] = [];

	const stableHashInputKey = Object.keys(stableHashMap).find((errorText) =>
		`${error.message || String(error)}`.includes(errorText),
	);
	if (stableHashInputKey) {
		// if the error matches one of the errors listed in the stableHashMap, produce a stable hash
		const stableHashInput = stableHashMap[stableHashInputKey];
		bytes = hashDataAsByte(stableHashInput);
	} else {
		// otherwise hash using the message & component stack
		bytes = hashDataAsByte(`${error.message || String(error)}${errorInfo.componentStack}`);
	}

	return (
		// the bitwise operations are expected below
		/* eslint-disable no-bitwise */
		(
			((bytes[0] & 0xff) |
				((bytes[1] & 0xff) << 8) |
				((bytes[2] & 0xff) << 16) |
				((bytes[3] & 0xff) << 24)) &
			/* eslint-enable no-bitwise */
			0x3fffffff
		) /* because we want a maximum of 6 characters */
			.toString(32 /* 5 bits per character */)
	);
}
